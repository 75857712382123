import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const Class10 = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>West Bengal Madhyamik Suggestion | Madhyamik Guide</title>
        <meta
          name="description"
          content="West Bengal Madhyamik Suggestion | Madhyamik Guide"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Madhyamik Guide
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      HOME
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a className="text-white" href="#">
                      WEST BENGAL
                    </a>
                  </li>
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    মাধ্যমিক
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              // style="min-height: 400px;"
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={require("../../img/about.jpg")}
                  alt=""
                  // style="object-fit: cover;"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                MADHYAMIK
              </h6>
              <h1 className="mb-4">Welcome to মাধ্যমিক</h1>
              <p className="mb-4">
                মধ্যমিক পরীক্ষা প্রস্তুতি: এখানে আসুন সহজে পড়ে সম্পূর্ণ পরীক্ষার
                সমাধান পেতে।
              </p>
              <p className="mb-4">
                মাধ্যমিক পরীক্ষার জন্য প্রস্তুতির জন্য আমরা তৈরি করছি বিষয়ে
                বিষয়গুলির বর্ণনা। এই পরীক্ষার জন্য প্রস্তুতি নিতে বিভিন্ন
                বিষয়ে ভালো ধারণা এবং সঠিক প্রস্তুতি গুরুত্বপূর্ণ। প্রত্যেক
                বিষয়ের কোর নির্দিষ্ট এবং উন্নতির জন্য নিয়ে যাওয়া প্রশ্নপত্র,
                বই এবং অন্যান্য সূত্রের সাথে পরিচিতি উন্নতির পথে সাহায্য করতে
                পারে। আমরা ছাত্রদের মাধ্যমিক পরীক্ষার জন্য প্রস্তুতির জন্য এই
                বিষয়গুলির বর্ণনা সরবরাহ করছি যাতে তারা সঠিকভাবে প্রস্তুত হতে
                পারেন।
              </p>
              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    নোটস
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    প্রশ্ন এবং উত্তর
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    ব্যাখ্যা
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    এম সি কিউ
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    পরীক্ষা
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    প্রস্তুতি যাচাই
                  </p>
                </div>
              </div>
              <a
                className="btn btn-primary py-3 px-5 mt-2"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.isbawb"
              >
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/West-Bengal-Class-10-English-Madhyamik-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    English <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/West-Bengal-Class-10-Bengali-Madhyamik-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    বাংলা <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate(
                  "/West-Bengal-Class-10-Physical-Science-Madhyamik-Guide"
                )
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ভৌতবিজ্ঞান <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/West-Bengal-Class-10-Geography-Madhyamik-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ভূগোল <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() =>
                navigate("/West-Bengal-Class-10-Life-Science-Madhyamik-Guide")
              }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    জীবনবিজ্ঞান <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              onClick={() => navigate("/History-Madhyamik-Preparation-Guide")}
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    ইতিহাস <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </div>
            <a
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.isbawb"
              // onClick={() =>
              //   (location.href =
              //     "https://play.google.com/store/apps/details?id=com.isbawb")
              // }
            >
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">
                    গণিত <br /> মাধ্যমিক প্রস্তুতি
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

Class10.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(Class10);
