/**
 *
 * HeaderComponent
 *
 */

import React, { memo } from "react";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CallIcon from "@mui/icons-material/Call";
import Typography from "@mui/material/Typography";
import Colors from "../../utils/Colors";
import { colors } from "@mui/material";

function HeaderComponent(props) {
  const { sections, title } = props;
  return (
    <React.Fragment>
      <Toolbar
        sx={{
          border: 1,
          backgroundColor: Colors.main,
          borderColor: "divider",
        }}
      >
        <Button size="small" style={{ color: Colors.white }}>
          {title}
        </Button>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          sx={{ flex: 1 }}
        ></Typography>
        <IconButton style={{ color: Colors.secondary }}>
          <CallIcon />
        </IconButton>
        <Typography
          color="inherit"
          align="center"
          sx={{ fontSize: 12, margin: 2, color: Colors.white }}
        >
          Talk to our experts
          <br />
          qlistthequestionbank@gmail.com
        </Typography>

        <Button
          variant="outlined"
          size="small"
          style={{ color: Colors.black, backgroundColor: Colors.secondary }}
        >
          Sign in
        </Button>
      </Toolbar>
    </React.Fragment>
  );
}

HeaderComponent.propTypes = {};

export default memo(HeaderComponent);
