import React, { memo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { compose } from "redux";
import { useNavigate } from "react-router-dom";

const শিক্ষাবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Helmet>
        <title>
          West Bengal Class 12 Guide | West Bengal HS Suggestion | Higher
          Secondary Guide | WBCHSE | WB | শিক্ষাবিজ্ঞান - উচ্চ মাধ্যমিক
          প্রস্তুতি
        </title>
        <meta
          name="description"
          content="West Bengal Class 12 Guide | West Bengal HS Suggestion | শিক্ষাবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি"
        />
      </Helmet>
      <div className="container-fluid bg-primary py-1 mb-1 page-header">
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                শিক্ষাবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="position-relative h-100"></div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3"></h6>
              <h1 className="mb-4">
                Welcome to শিক্ষাবিজ্ঞান - উচ্চ মাধ্যমিক প্রস্তুতি
              </h1>
              <p className="mb-4">
                প্রতিটি অধ্যায়ের সাথে প্রস্তুতির সহায়ক সামগ্রী ও লিঙ্ক সরবরাহ
                করা হয়েছে যাতে ছাত্ররা তাদের অধ্যয়নে সহায়তা পেতে পারেন। এই
                পৃষ্ঠাটি ছাত্রদের পড়াশোনা প্রক্রিয়ার জন্য একটি গুরুত্বপূর্ণ
                সহায়ক সরঞ্জাম হিসাবে কাজ করতে পারে।
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      শিখন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিখনের-কৌশল-Guide"
                        )
                      }
                    >
                      শিখনের কৌশল
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিক্ষায়-রাশিবিজ্ঞান-Guide"
                        )
                      }
                    >
                      শিক্ষায় রাশিবিজ্ঞান
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      ভারতীয় সংবিধানে উল্লিখিত শিক্ষা সম্পর্কে বিভিন্ন ধারা
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      বিশ্ববিদ্যালয় শিক্ষা কমিশন (1948-49)
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      মাধ্যমিক শিক্ষা কমিশন (1952-53)
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      ভারতীয় শিক্ষা কমিশন
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-জাতীয়-শিক্ষানীতি-Guide"
                        )
                      }
                    >
                      জাতীয় শিক্ষানীতি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-ব্যতিক্রমধর্মী-শিশুর-শিক্ষা-Guide"
                        )
                      }
                    >
                      ব্যতিক্রমধর্মী শিশুর শিক্ষা
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      প্রাথমিক শিক্ষার সর্বজনীনতা
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        navigate(
                          "/শিক্ষাবিজ্ঞান-উচ্চ-মাধ্যমিক-প্রস্তুতি-শিক্ষার-বিশ্বব্যাপী-দৃষ্টিভঙ্গি-Guide"
                        )
                      }
                    >
                      শিক্ষার বিশ্বব্যাপী দৃষ্টিভঙ্গি
                    </a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-mobile text-primary me-2"></i>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.isbawb"
                    >
                      শিক্ষা প্রযুক্তিবিদ্যা
                    </a>
                  </p>
                </div>
              </div>
              <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

শিক্ষাবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect, memo)(শিক্ষাবিজ্ঞানউচ্চমাধ্যমিকপ্রস্তুতি);
